import { APP_INITIALIZER, ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@common/authGuard/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { WacaHttpInterceptor } from '@common/interceptor/http.interceptor';
import { ConfigurationRepository } from '@domain/repositories/configuration.repository';
import { ConfigurationImplRepository } from '@data/repositories/configuration.impl.repository';
import { AuthenticationRepository } from '@domain/repositories/authentication.repository';
import { AuthenticationImplRepository } from '@data/repositories/authentication.impl.repository';
import { ChapterRepository } from '@domain/repositories/chapter.repository';
import { ChapterImplRepository } from '@data/repositories/chapter.impl.repository';
import { PostRepository } from '@domain/repositories/post.repository';
import { PostImplRepository } from '@data/repositories/post.impl.repository';
import { AuthGuard } from '@common/authGuard/Auth.guard';
import { ProfileRepository } from '@domain/repositories/profile.repository';
import { ProfileImplRepository } from '@data/repositories/profile.impl.repository';
import { UserRepository } from '@domain/repositories/user.repository';
import { UserImplRepository } from '@data/repositories/user.impl.repository';
import { ServiceRepository } from '@domain/repositories/service.repository';
import { ServiceImplRepository } from '@data/repositories/service.impl.repository';
import { ReadingHistoryRepository } from '@domain/repositories/reading-history.repository';
import { ReadingHistoryImplRepository } from '@data/repositories/reading-history.impl.repository';
import { EventsRepository } from '@domain/repositories/events.repository';
import { EventsImplRepository } from '@data/repositories/events.impl.repository';
import { ReviewImplRepository } from '@data/repositories/review.impl.repository';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SearchRepository } from '@domain/repositories/search.repository';
import { SearchImplRepository } from '@data/repositories/search.impl.repository';
import { IconsModule } from '@common/icons/icons.module';
import { BookmarkRepository } from '@domain/repositories/bookmark.repository';
import { BookmarkImplRepository } from '@data/repositories/bookmark.impl.repository';
import { ReviewRepository } from '@domain/repositories/review.repository';
import { PixelModule } from 'ngx-pixel';
import { LoginDialogComponent } from '@components/login-dialog/login-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DialogNotificationComponent } from '@components/dialog-notification/dialog-notification.component';
import { BannerRepository } from '@domain/repositories/banner.repository';
import { BannerImplRepository } from '@data/repositories/banner.impl.repository';
import { UserAssetsRepository } from '@domain/repositories/userAssets.repository';
import { UserAssetsImplRepository } from '@data/repositories/userAssets.impl.repository';
import { DialogNotificationService } from '@components/dialog-notification/dialog-notification.service';
import { ToastNotificationModule } from '@components/toast-notification';
import { DialogConfirmationService } from '@components/dialog-confirmation/dialog-confirmation.service';
import { DialogConfirmationComponent } from '@components/dialog-confirmation/dialog-confirmation.component';
import { MonetisakuRepository } from '@domain/repositories/monetisaku.repository';
import { MonetisakuImplRepository } from '@data/repositories/monetisaku.impl.repository';
import { PaymentRepository } from '@domain/repositories/payment.repository';
import { PaymentImplRepository } from '@data/repositories/payment.impl.repository';
import { SafePipe } from '@common/pipe/SafePipe';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button/button.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NotificationBannerRepository } from '@domain/repositories/notificationBanner.repository';
import { NotificationBannerImplRepository } from '@data/repositories/notificationBanner.impl';
import { NotificationRepository } from '@domain/repositories/notification.repository';
import { NotificationImplRepository } from '@data/repositories/notification.impl.repository';
import { StoreRepository } from '@domain/repositories/store.repository';
import { StoreImplRepository } from '@data/repositories/store.impl.repository';
import { MsUserHttpClient, msUserHttpClientCreator } from '@common/httpClinet/msUser/msUser.client';
import { MsFileHttpClient, msFileHttpClientCreator } from '@common/httpClinet/msFile/msFile.client';
import {
	MsStoreHttpClient,
	msStoreHttpClientCreator
} from '@common/httpClinet/msStore/msStore.client';
import {
	MsPaymentHttpClient,
	msPaymentHttpClientCreator
} from '@common/httpClinet/msPayment/msPayment.client';
import {
	MsAnalyticsHttpClient,
	msAnalyticsHttpClientCreator
} from '@common/httpClinet/msAnalytics/msAnalytics.client';
import {
	MsNotificationHttpClient,
	msNotificationHttpClientCreator
} from '@common/httpClinet/msNotification/msNotification.client';
import { UserPreferenceRepository } from '@domain/repositories/userPreference.repository';
import { UserPreferenceImplRepository } from '@data/repositories/userPreference.impl.repository';
import { CommentsRepository } from '@domain/repositories/comments.repository';
import { CommentsImplRepository } from '@data/repositories/comments.impl.repository';
import { WacaAiRepository } from '@domain/repositories/wacaAi.repository';
import { WacaAiImplRepository } from '@data/repositories/wacaAi.impl.repository';
import { DialogPaidConfirmationModule } from '@components/dialog-paid-confirmation/dialog-paid-confirmation.module';
import { BottomNavigationModule } from '@components/bottom-navigation/bottom-navigation.module';
import { BottomNavigationShopModule } from '@components/bottom-navigation-shop/bottom-navigation-shop.module';
import { BottomSheetShareComponent } from '@components/bottom-sheet-share/bottom-sheet-share.component';
import localeId from '@angular/common/locales/id';
import { JoyrideModule } from 'ngx-joyride';
import { ImageCropperDialogModule } from '@components/image-cropper-dialog/image-cropper-dialog.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogConfirmationWriterModule } from '@components/dialog-confirm-writer/dialog-confirm-writer.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
	getAnalytics,
	provideAnalytics,
	ScreenTrackingService,
	UserTrackingService
} from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import {
	AngularFireRemoteConfig,
	AngularFireRemoteConfigModule,
	DEFAULTS,
	SETTINGS
} from '@angular/fire/compat/remote-config';
import { EbookRepository } from '@domain/repositories/ebook.repository';
import { EbookImplRepository } from '@data/repositories/ebook.impl.repository';
import { EbookBookmarkRepository } from '@domain/repositories/ebook.bookmark.repository';
import { EbookBookmarkImplRepository } from '@data/repositories/ebook.bookmark.impl.repository';
import { BottomSheetShareEbookComponent } from '@components/bottom-sheet-share-ebook/bottom-sheet-share-ebook.component';
import { PublisherRepository } from '@domain/repositories/publisher.repository';
import { PublisherImplRepository } from '@data/repositories/publisher.impl.repository';

registerLocaleData(localeId);

@NgModule({
	declarations: [
		AppComponent,
		LoginDialogComponent,
		DialogNotificationComponent,
		DialogConfirmationComponent,
		BottomSheetShareComponent,
		BottomSheetShareEbookComponent,
		SafePipe
	],
	imports: [
		CommonModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		MatDialogModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule,
		BrowserAnimationsModule,
		MatBottomSheetModule,
		IconsModule,
		PixelModule.forRoot({ enabled: true, pixelId: '3792422100990327' }),
		ToastNotificationModule.forRoot(),
		DialogPaidConfirmationModule.forRoot(),
		JoyrideModule.forRoot(),
		ImageCropperDialogModule.forRoot(),
		DialogConfirmationWriterModule.forRoot(),
		MatInputModule,
		ReactiveFormsModule,
		ButtonModule,
		BottomNavigationModule,
		BottomNavigationShopModule,
		MatProgressSpinnerModule,

		AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
		AngularFireAuthModule,
		AngularFireRemoteConfigModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAnalytics(() => getAnalytics()),
		provideAuth(() => getAuth()),
		providePerformance(() => getPerformance()),
		provideRemoteConfig(() => getRemoteConfig())
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'id-ID' },
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: WacaHttpInterceptor,
			multi: true
		},
		AuthService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			})
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		},
		{
			provide: ReviewRepository,
			useClass: ReviewImplRepository
		},
		{
			provide: EventsRepository,
			useClass: EventsImplRepository
		},
		{
			provide: ServiceRepository,
			useClass: ServiceImplRepository
		},
		{
			provide: UserRepository,
			useClass: UserImplRepository
		},
		{
			provide: ConfigurationRepository,
			useClass: ConfigurationImplRepository
		},
		{
			provide: AuthenticationRepository,
			useClass: AuthenticationImplRepository
		},
		{
			provide: ChapterRepository,
			useClass: ChapterImplRepository
		},
		{
			provide: PostRepository,
			useClass: PostImplRepository
		},
		{
			provide: EbookRepository,
			useClass: EbookImplRepository
		},
		{
			provide: ProfileRepository,
			useClass: ProfileImplRepository
		},
		{
			provide: ReadingHistoryRepository,
			useClass: ReadingHistoryImplRepository
		},
		{
			provide: BookmarkRepository,
			useClass: BookmarkImplRepository
		},
		{
			provide: SearchRepository,
			useClass: SearchImplRepository
		},
		{
			provide: BannerRepository,
			useClass: BannerImplRepository
		},
		{
			provide: UserAssetsRepository,
			useClass: UserAssetsImplRepository
		},
		{
			provide: MonetisakuRepository,
			useClass: MonetisakuImplRepository
		},
		{
			provide: PaymentRepository,
			useClass: PaymentImplRepository
		},
		{
			provide: NotificationBannerRepository,
			useClass: NotificationBannerImplRepository
		},
		{
			provide: NotificationRepository,
			useClass: NotificationImplRepository
		},
		{
			provide: UserPreferenceRepository,
			useClass: UserPreferenceImplRepository
		},
		{
			provide: CommentsRepository,
			useClass: CommentsImplRepository
		},
		{
			provide: WacaAiRepository,
			useClass: WacaAiImplRepository
		},
		{
			provide: EbookBookmarkRepository,
			useClass: EbookBookmarkImplRepository
		},
		{
			provide: StoreRepository,
			useClass: StoreImplRepository
		},
		{
			provide: PublisherRepository,
			useClass: PublisherImplRepository
		},
		{
			provide: MsUserHttpClient,
			useFactory: msUserHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MsFileHttpClient,
			useFactory: msFileHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MsPaymentHttpClient,
			useFactory: msPaymentHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MsAnalyticsHttpClient,
			useFactory: msAnalyticsHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MsNotificationHttpClient,
			useFactory: msNotificationHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MsStoreHttpClient,
			useFactory: msStoreHttpClientCreator,
			deps: [HttpClient]
		},
		{
			provide: MatDialogRef,
			useValue: {}
		},
		DialogNotificationService,
		DialogConfirmationService,
		ScreenTrackingService,
		UserTrackingService,
		{ provide: DEFAULTS, useValue: { enableAwesome: true } },
		{
			provide: SETTINGS,
			useFactory: () => (isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {})
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private remoteConfig: AngularFireRemoteConfig) {
		this.remoteConfig.fetchAndActivate().then((result) => {
			console.log(`activating remote config: ${result}`);
		});
	}
}
